export const networks = [40, 41]

export const ChainId = {
  // MAINNET: 1,
  TELOS: 40,
  TELOSTESTNET: 41,
};

export const routerAddress = new Map();
// routerAddress.set(ChainId.MAINNET, "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D");
routerAddress.set(ChainId.TELOS, "0xF9678db1CE83f6f51E5df348E2Cc842Ca51EfEc1");
routerAddress.set(ChainId.TELOSTESTNET, "0xE099F3EB1398dBAD904FCD6f9De7a8c2C36824AA");


