import * as chains from './chains';

// If you add coins for a new network, make sure Weth address (for the router you are using) is the first entry
const TELOSCoins = [
  {
    name: "Telos",
    abbr: "TLOS",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "WTelos",
    abbr: "WTLOS",
    address: "0xd102ce6a4db07d247fcc28f366a623df0938ca9e", // Weth address is fetched from the router
  },
  {
    name: "Charm",
    abbr: "CHARM",
    address: "0xd2504a02fABd7E546e41aD39597c377cA8B0E1Df",
  },
  {
    name: "dummy☻DAO",
    abbr: "DMMY",
    address: "0x2f15F85a6c346C0a2514Af70075259e503E7137B",
  },
  {
    name: "TelosFellas",
    abbr: "TFELLAS",
    address: "0xF5bA374CF1e85d1a44EC989c5d8A25C4eACA078E",
  },
  {
    name: "Glue Dog",
    abbr: "DOG",
    address: "0x5C8F2334BD0e7B76e15a7869E31c1F1A654a2B62",
  }
]

const TELOSTESTNETCoins = [
  {
    name: "Telos",
    abbr: "TLOS",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "WTLOS",
    abbr: "WTLOS",
    address: "0xaE85Bf723A9e74d6c663dd226996AC1b8d075AA9", // Weth address is fetched from the router
  },
  {
    name: "Dummy",
    abbr: "DMMY",
    address: "0x662f285D38950c4ba7Ae44775Cc4cc3A9C122f76",
  }
]


const COINS = new Map();
COINS.set(chains.ChainId.TELOS, TELOSCoins);
COINS.set(chains.ChainId.TELOSTESTNET, TELOSTESTNETCoins);

export default COINS