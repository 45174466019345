import React from "react";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  paperContainer: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    maxWidth: 700,
    margin: "auto",
    marginTop: "200px",
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  hr: {
    width: "100%",
  },
  balance: {
    padding: theme.spacing(1),
    overflow: "wrap",
    textAlign: "center",
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.4),
  },
  footer: {
    marginTop: "155px",
  },
});

const useStyles = makeStyles(styles);

function ConnectWalletPage() {
  const classes = useStyles();
  return (
    <div>
      <div className="Title">
        <h1 className="navbar-logo">
        </h1>
      </div>

      <Container>
        <Paper className={classes.paperContainer}>
          <Typography
            variant="h6"
            className={classes.title}
            color="#fff"
          >
            Please connect an Ethereum wallet to your browser to use the
            application
          </Typography>
        </Paper>
      </Container>

      <Grid
        container
        className={classes.footer}
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
      >
        <p>dummy ☻ swap v1.0 ☻ telos <br></br><br></br><strong>[2/17/22] what's new :</strong><br></br>- Tfellas Added!<br></br>- Glue Dog Added!<br></br><br></br><strong>known bugs :</strong><br></br>- Approves spend each trade.<br></br> - Browser compatibility: [chrome, brave, metamask app].<br></br>- No slippage setting. <br></br>- Dummy Swap uses <a target="_blank" href="https://omnidex.finance">OmniDex</a> router, swap there as well.<br></br>- Add telos EVM Mainnet to Metamask here: <a target="_blank" href="https://chainlist.org">chainlist.org</a>.<br></br><br></br>
        dummy ☻ swap | {" "}
          <a target="_blank" href="https://twitter.com/dummy_finance">twitter</a> | <a target="_blank" href="https://discord.gg/THN8nmYwJe">discord</a>
        </p>
      </Grid>
    </div>
  );
}

export default ConnectWalletPage;
